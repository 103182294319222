import WhiteButton from '../WhiteButton';
import RedButton from '../RedButton';
import style from './style.module.css'
import { goToUrl } from "../../utils/constants"
import { useLogin } from '../../context/LoginContext';
import LoginPopup from '../../components/LoginPopup';
import LoginSubscribe from '../../components/LoginSubscribe';



function NotPermitted() {

    const backToHpButtonText = 'Back to Homepage'
    // const logInHpButtonText = 'Log In'
    // const {showLogin} = useLogin()
    // const {showLoginModal, hideLogin, doLogin} = useLogin()


    return (
        <div className={`${style["not-logged-in__container"]}`}>
            <h3>You don’t have permission to view this page</h3>
            <div className={`${style["not-logged__flex"]}`}>
                <WhiteButton textButton={backToHpButtonText} margin='margin-right' onClick={(e) => goToUrl('/', e)}/>
                {/* <RedButton textButton={logInHpButtonText} onClick={showLogin}/> */}
            </div>
        </div>
    )
}

export default NotPermitted
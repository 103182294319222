import MembershipTitle from '../../components/MembershipTitle';
import style from './style.module.css';
import MemberCard from '../../components/MemberCard';
import { useEffect, useState } from 'react';
import { getMemberArea, getUsersCountries, getMemberAreaSearch } from '../../utils/api';
import Pagination from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import WhiteMobileSearch from '../../assets/whiteMobileSearch.svg';
import SearchDropdown from '../../components/SearchDropdown';
import NotloggedIn from "../../components/NotLoggedIn";
import { useUser } from "../../context/UserContext";
import { set } from 'date-fns';
import { toast } from 'react-toastify';
import NotPermitted from '../../components/NotPermitted';

const ITEMS_PER_PAGE = 24

function MembershipAreaPage() {
    const title = 'Member Area';
    const [users, setUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [pageElements, setPageElements] = useState([])
    const [countries, setCountries] = useState([])
    const [countryParam, setCountryParam] = useState('')
    const [fullnameParam, setFullnameParam] = useState('')
    const [shouldReset, setShouldReset] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const {isLogged, user} = useUser()
    console.log(user)
  
    useEffect(() => {
      return () => {
        setCurrentPage(1)
      };
    }, []);
  
    useEffect(() => {
        if (users && users.length > 0) {
            const start = currentPage * ITEMS_PER_PAGE // 1 * 10 = 10
            const end = currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE // 1 * 10 + (10 - 1) = 9 ù
  
            setPageElements(users.slice(start, end))
        }
    }, [currentPage, users]);  

    const handleSearch = (e) => {
      //console.log(e.name);
      if (e.name !== 'Country') {
        setCountryParam(e.name);
      }
    }

    const handleSearchChange = (e) => {
      //console.log(e.target.value);
      setFullnameParam(e.target.value);
    }

    function handleSearchClick(e){

      let query;

      if (countryParam !== '' && fullnameParam !== '') {
        query = `?country=${countryParam}&fullname=${fullnameParam}`;
      } else if (countryParam !== '') {
        query = `?country=${countryParam}`;
      } else if (fullnameParam !== '') {
        query = `?fullname=${fullnameParam}`;
      }
      //console.log(query);

      if (query) {
        handleMemberAreaSearch(query);
      }
    }

    const handleMemberAreaSearch = async (query) => {
      try {
        const res = await getMemberAreaSearch(query);
        //console.log(res);
        if (res.user.length > 0) {
          setUsers(res.user);
          setShowResults(!showResults);
        } else {
          setShowResults(!showResults);
          toast.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    }

    function handleRefreshPage(){
      handleGetMemberArea();
    }

    const resetValue = (value) => {
      if (value === 'Country') {
        setCountryParam('');
      }
    }

    const resetTextValue = (value) => {
      setFullnameParam('');
    }

    const handleGetMemberArea = async () => {
      try {
        const res = await getMemberArea();
        if (res) {
          setUsers(res);
          setCountryParam('');
          setFullnameParam('');
          setShouldReset(!shouldReset);
          setShowResults(false);
        }
      } catch (error) {
        console.log(error);
      }
      
    }
      
    useEffect(async () => {
      const res = await getMemberArea()
      if (res) setUsers(res)
      const resCountries = await getUsersCountries()
      if (resCountries) setCountries(resCountries)
    }, [user])

  return (
    <div className="main__container text-center membership__section">
      <MembershipTitle title={title} AlignText='center'/> 
      <p className={`${style['member__text']}`}>Welcome to IDI member directory. You will find information and contact details of IDI members who allowed us to share their personal information and contacts with other members.</p>
      {isLogged && (!user.remove_fee || (user.remove_fee && user.membership)) ? (
      <>
      <div className={`${style['memberpage__search']}`}>
        <span className={`${style['memberpage__search__text']}`}>Search for Name, Country</span>
        <SearchBar placeholderText={'Name'} value={fullnameParam} onChange={(e) => handleSearchChange(e)} onClick={(e) => handleSearchClick(e)}/>
        <div className={`${style['memberpage__search__divider']}`}>
          <SearchDropdown defaultString={'Country'} resetValue={resetValue} options={countries} stringAttribute="name" handleChoice={(e) => handleSearch(e)} shouldReset={shouldReset}/>
          
          <div className={`${style['memberpage__search__results']}`}>
            <img src={WhiteMobileSearch} className={`${style["mobile_search_img"]}`} alt="search_img" onClick={(e) => handleSearchClick(e)}/>
            {showResults && <span className={`${style["search_reset_link"]}`} onClick={() => handleRefreshPage()}>Reset</span>}
          </div>
        </div>
      </div>
      <div className={`${style['memberpage__flex']}`}>
        {pageElements.map (user => <MemberCard values={user} />)}
      </div>
      <Pagination currentPage={currentPage} items={users} setCurrentPage={setCurrentPage} itemsPerPage={ITEMS_PER_PAGE} />
      </>
      ) : (user.remove_fee && !user.membership) ? <NotPermitted /> : <NotloggedIn/>}
    </div>
  );
}

export default MembershipAreaPage;
import React, { useEffect, useState } from "react";
import { Formik, useField, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import CheckboxField from "../CheckboxField";
import InputSelect from "../InputSelect";
import InputField from "../InputField";
import MembershipTitle from "../MembershipTitle";
import PageDescription from "../PageDescription";
import style from "./style.module.css";
import { emailValidation } from "../../utils/validations";

const privacyText = (
  <div className="full-width">
    Do you accept the IDI{" "}
    <a
      className="privacy-link"
      href="https://www.idiproject.com/wp-content/uploads/2022/11/IDI-Subscription-and-Membership-Agreement.pdf"
      target="_blank"
      rel="noopener noreferrer"
    >
      Membership Agreement
    </a>{" "}
    and{" "}
    <a
      className="privacy-link"
      href="/idi-privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy?{" "}
    </a>
  </div>
);

const urlParams = new URLSearchParams(window.location.search);

const feeZero = urlParams.get("fee-zero");

const feeZeroPrivacyText = (
  <div className="full-width">
    Do you accept the IDI{" "}
    <a
      className="privacy-link"
      href="/idi-privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy?{" "}
    </a>
  </div>
);

const emailMarketingText = (
  <div className="full-width">Enable IDI newsletter</div>
);

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  surname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .min(6, "Please use at least 6 numbers")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid"
    ),
  email: Yup.string()
    .required("Required")
    .email("Please enter a valid email")
    .matches(/^\S+$/, "Please remove any spaces")
    .test("email-is-not-used", "Email is already in use", function() {
      return emailValidation;
    }),
  privacy_policy: Yup.boolean().oneOf([true], "Field must be checked"),
  field_of_activity: Yup.string().required("Required"),
  email_marketing: Yup.boolean()
    .oneOf([true, false])
    .required("Field must be selected")
    .nullable(),
  password: Yup.string()
    .required("Required")
    .min(8, "Please use at least 8 characters")
    .matches(/[0-9]+/, "Please use at least 1 number")
    .matches(/[a-z]+/, "Please use at leat one lowercase letter")
    .matches(/[A-Z]+/, "Please use at leat one uppercase letter")
    .matches(/[-+_!@#$%^&*.,?]+/, "Please use at least one special character"),
  password_confirm: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

function Signup({
  shouldValidate,
  resetValidation,
  saveAndContinue,
  values,
  title,
  description,
}) {
  const defaultValues = {
    name: values && values.hasOwnProperty("name") ? values.name : "",
    surname: values && values.hasOwnProperty("surname") ? values.surname : "",
    phone: values && values.hasOwnProperty("phone") ? values.phone : "",
    email: values && values.hasOwnProperty("email") ? values.email : "",
    field_of_activity:
      values && values.hasOwnProperty("field_of_activity")
        ? values.field_of_activity
        : "",
    password:
      values && values.hasOwnProperty("password") ? values.password : "",
    password_confirm:
      values && values.hasOwnProperty("password_confirm")
        ? values.password_confirm
        : "",
    privacy_policy:
      values && values.hasOwnProperty("privacy_policy")
        ? values.privacy_policy
        : false,
    email_marketing:
      values && values.hasOwnProperty("email_marketing")
        ? values.email_marketing
        : null,
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      saveAndContinue(values);
    },
  });

  useEffect(() => {
    if (values !== undefined) {
      Object.keys(values).forEach((key) => {
        formik.setFieldValue(key, values[key]);
      });
    }
  }, [values])

  const content =
    description ||
    "Please, enter your personal information in the boxes below. (Field marked with *  are required)";

  useEffect(() => {
    if (shouldValidate)
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) {
          console.log(formik.values);
          console.log(errors);
          resetValidation();
        } else formik.handleSubmit();
      });
  }, [shouldValidate]);

  return (
    <div className="membership__section">
      <div className={`${style["signup__form"]}`}>
        <MembershipTitle title={title || "Sign Up"} />
        <PageDescription textContent={content} />
        <Formik enableReinitialize={true}>
        <form
          className={`${style["form__container"]}`}
          onSubmit={formik.handleSubmit}
        >
          <InputField
            placeholderText="Name*"
            fieldType="text"
            name="name"
            hasErrors={formik.touched.name && formik.errors.name}
            handleChange={formik.handleChange}
            value={formik.values.name}
            errors={formik.errors.name}
          />
          <InputField
            placeholderText="Surname*"
            fieldType="text"
            name="surname"
            hasErrors={formik.touched.surname && formik.errors.surname}
            handleChange={formik.handleChange}
            value={formik.values.surname}
            errors={formik.errors.surname}
          />
          <InputField
            placeholderText="Phone"
            fieldType="text"
            name="phone"
            hasErrors={formik.touched.phone && formik.errors.phone}
            handleChange={formik.handleChange}
            value={formik.values.phone}
            errors={formik.errors.phone}
          />
          <InputField
            placeholderText="Email*"
            fieldType="email"
            name="email"
            hasErrors={formik.touched.email && formik.errors.email}
            handleChange={formik.handleChange}
            value={formik.values.email}
            errors={formik.errors.email}
          />
          <InputField
            placeholderText="Password*"
            fieldType="password"
            name="password"
            hasErrors={formik.touched.password && formik.errors.password}
            handleChange={formik.handleChange}
            value={formik.values.password}
            errors={formik.errors.password}
          />
          <InputField
            placeholderText="Confirm Password*"
            fieldType="password"
            name="password_confirm"
            hasErrors={
              formik.touched.password_confirm && formik.errors.password_confirm
            }
            handleChange={formik.handleChange}
            value={formik.values.password_confirm}
            errors={formik.errors.password_confirm}
          />
          <div className={`${style["email-marketing__field"]}`}>
            <InputSelect
            placeholderText="Working at*"
            name={"field_of_activity"}
            onChange={(event) => {
              formik.setFieldValue("field_of_activity", event.target.value);
            }}
            values={[
              { value: "company", label: "Company" },
              { value: "law firm", label: "Law firm" },
              { value: "other", label: "Other" },
            ]}
            selectedValue={formik.values.field_of_activity}
            errors={formik.errors.field_of_activity}
          />{" "}
          <InputSelect
            placeholderText="Enable IDI Newsletter"
            name={"email_marketing"}
            onChange={(event) => {
              formik.setFieldValue(
                "email_marketing",
                event.target.value === "true" ? true : false
              );
            }}
            values={[
              { value: false, label: "NO" },
              { value: true, label: "YES" },
            ]}
            selectedValue={formik.values.email_marketing}
            errors={formik.errors.email_marketing}
          />
        </div>
        <CheckboxField
          hasErrors={
            formik.touched.privacy_policy && formik.errors.privacy_policy
          }
          checkboxText={!feeZero ? privacyText : feeZeroPrivacyText}
          name="privacy_policy"
          handleChange={(e) => {
            formik.setFieldValue("privacy_policy", e.target.checked);
          }}
          value={formik.values.privacy_policy}
          errors={formik.errors.privacy_policy}
        />
        </form>
        </Formik>
        
      </div>
    </div>
  );
}

export default Signup;

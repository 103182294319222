import React, { useEffect, useState } from "react";
import style from './style.module.css';
import SignUp from '../../components/SignUp'
import CompanyInformation from '../../components/CompanyInformation'
import AddUser from '../../components/AddUser';
import FlowManager from '../../components/FlowManager';
import SignUpConferenceSection from '../../components/SignUpConferenceSection';
import PaymentScreen from '../../components/PaymentScreen';
import ShoppingCartSection from '../../components/ShoppingCartSection';
import { useCart } from "../../context/CartContext";
import { conferenceCart } from "../../utils/defaultValues";



function SignupAndConference() {
    const {cart, saveCart} = useCart()
    const steps = [
        {
            id: 'mainUser',
            title: 'Sign Up',
            component: SignUp,
            redButtonLabel: 'Continue',
        }, 
        {
            id: 'company',
            title: 'Company Information',
            component: CompanyInformation,
            redButtonLabel: 'Create new Account',
            whiteButtonLabel:'Back to',     
        },
        {
            id: 'additionalUsers',
            title: 'Add Person',
            component: AddUser,
            redButtonLabel: 'Continue',
            whiteButtonLabel:'Back to',
        },
        {
            id: 'conferences',
            title: 'Conferences & Seminars',
            component: SignUpConferenceSection,
            redButtonLabel: 'Continue',
            whiteButtonLabel:'Back to',
        },
        {
            id: 'cart',
            title: 'Shopping Cart',
            component: ShoppingCartSection,
            redButtonLabel: 'Checkout',
            whiteButtonLabel:'Back to',
        },
        {
            id: 'billing_information',
            title: 'Payment Details',
            component: PaymentScreen,
            redButtonLabel: 'Pay Now',
            whiteButtonLabel:'Back to',
        }
    ];

    useEffect(() => {

        saveCart({
            ...cart,
            additionalUsers: [],
            companyUsers: [],
        })

        // per avere il form precompilato

        //    saveCart({
        //     ...conferenceCart
        //    });
    }, [])

    
    return (
        <>
        <div className={`${style["signup__container"]}`}>
            <FlowManager stepArray={steps} defaultStep={0}/>
        </div>
        </>
    )
}

export default SignupAndConference

import React, { useState, useEffect } from "react"
import SignupStep from '../SignupStep'
import style from './style.module.css';
import WhiteButton from '../WhiteButton'
import RedButton from '../RedButton'
import Container from 'react-bootstrap/Container';
import { useCart } from '../../context/CartContext';

function FlowManager({stepArray, defaultStep}) {
    const [stepNumb, setStepNumb] = useState(defaultStep || 0);
    const [validations, setValidations] = useState({})
    const [navigationHidden, setNavigationHidden] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [stepsHidden, setStepsHidden] = useState(false)
    const {cart, saveCart} = useCart()

    const increaseStepNum = (e) =>{
        e.preventDefault();
        // Perform a validation of the step
        setValidations(previousState => { return {...previousState, [stepNumb]: true}})
    }

    const decreaseStepNum = (e) =>{
        e.preventDefault();
        setStepNumb(stepNumb - 1);
    }

    const resetValidation = (stepNumb) => {
      setValidations(previousState => { return {...previousState, [stepNumb]: false}})
    }

    const saveAndContinue = (stepNumb, values) => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString);
      const freetrial = urlParams.get('freetrial')
      const streaming = urlParams.get('streaming')
      const feeZero = urlParams.get('fee-zero')
      saveCart({...cart, [stepArray[stepNumb].id]: values, freetrial, streaming})
      resetValidation(stepNumb)

      // Increment steps if more steps are present
      if (stepArray[stepNumb + 1]) setStepNumb(stepNumb + 1);
    }

    const hideNavigation = () => setNavigationHidden(true)
    const showNavigation = () => setNavigationHidden(false)
    const hideSteps = () => setStepsHidden(true)

    useEffect(() => {
      const validationArray = stepArray.map((step, index) => false)
      setValidations(validationArray)
    }, [stepArray])

    useEffect(() => {
      setStepNumb(defaultStep)
    }, [defaultStep])

    useEffect(() => {
      if (stepArray[stepNumb].hideNavigation) hideNavigation()
      else showNavigation()
    }, [stepNumb])

    return( 
      <>
        <Container>
          <div>
              {stepArray[stepNumb].component && 
                React.createElement(stepArray[stepNumb].component, 
                  {
                    ...stepArray[stepNumb].props,
                    shouldValidate: validations[stepNumb], 
                    resetValidation: () => resetValidation(stepNumb), 
                    saveAndContinue: (values) => saveAndContinue(stepNumb, values),
                    values: cart[stepArray[stepNumb].id],
                    hideNavigation: hideNavigation,
                    hideSteps: hideSteps,
                    showNavigation: showNavigation,
                    setIsLoading: setIsLoading,
                  }
                )
              }
              {!navigationHidden && <div className={`${style["buttons_container"]}`} style={stepNumb === 0 ? {justifyContent:  'flex-end'} : {justifyContent: 'space-between'}}>
                  {stepNumb != 0 && <WhiteButton textButton={stepArray[stepNumb].whiteButtonLabel} onClick={decreaseStepNum} disabled={isLoading} />}
                  <RedButton textButton={stepArray[stepNumb].redButtonLabel} onClick={increaseStepNum} disabled={isLoading} loading={isLoading} />
              </div>}
          </div>
        </Container>
        {!stepsHidden && <div className={`${style["signup_step-section"]}`}>
            <SignupStep stepsArray={stepArray} currentStep={stepNumb}/>
        </div>}
      </>
    )
  
}

export default FlowManager

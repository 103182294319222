import { bookConference, renewMembership, signUp } from "./api";

export const getCartPaymentMethod = async (elements, stripe, CardElement, resetPayment) => {
    try {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        // Show error to your customer.
        console.log(error.message);
        resetPayment()
        return false;
      } else {
        return paymentMethod
      }
    } catch(e) {
      console.log(e)
      resetPayment()
    }
  }


export const doRegistration = async (conference, cart) => {
    if (conference) {
        try {
          const res = cart.renew ? await bookConference(cart) : await signUp(cart, cart.stripe_customer_id)
          console.log("conference", res)
          return res
        } catch(e) {
          console.log(e)
          return e
        }
      } else {
        try {
          const res = cart.renew ? await renewMembership(cart, cart.stripe_customer_id) : await signUp(cart, cart.stripe_customer_id)
          console.log(res)
          return res
        } catch(e) {
          console.log(e)
          return e
        }
      }
}
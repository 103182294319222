import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import InputField from '../InputField';
import MembershipTitle from '../MembershipTitle';
import PageDescription from '../PageDescription';
import style from './style.module.css';
import InputSelect from "../InputSelect";
import { countries } from "../../utils/countries";
import { checkCompany, signUp } from "../../utils/api";
import { useCart } from "../../context/CartContext";
import { ca } from "date-fns/locale";
import { createCustomer } from "../../utils/stripe";
import ThankYou from "../ThankYou";

const schema = Yup.object().shape({
    /* type: Yup.string().required("Required"), */
    name: Yup.string().required("Required"),
    vat: Yup.string(),
    country: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    province: Yup.string().required("Required"),
    postal_code: Yup.string().required("Required"),
})

function CompanyInformation({shouldValidate, resetValidation, saveAndContinue, values, hideSteps, hideNavigation }) {
    const {cart, saveCart} = useCart()
    const title = 'Company Information';
    const content = 'Please, fill-in your Company Information';
    const feeZero = cart.mainUser.remove_fee
    const [showThankYou, setShowThankYou] = useState(false)

    const formik = useFormik({
        initialValues: { 
            /* type: values && values.hasOwnProperty('type') ? values.type : '',  */
            name: values && values.hasOwnProperty('name') ? values.name : '', 
            vat: values && values.hasOwnProperty('vat') ? values.vat : '', 
            country: values && values.hasOwnProperty('country') ? values.country : '', 
            address: values && values.hasOwnProperty('address') ? values.address : '', 
            city: values && values.hasOwnProperty('city') ? values.city : '', 
            province: values && values.hasOwnProperty('province') ? values.province : '', 
            postal_code: values && values.hasOwnProperty('postal_code') ? values.postal_code : '' ,
            type: values && values.hasOwnProperty('type') ? values.type : '' ,
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            const res = await checkCompany(values)
            console.log(res)
            saveAndContinue({ ...values, companyExists: res.company_exists})
        }
      });

    useEffect(() => {
        if (shouldValidate) formik.validateForm().then(errors => {
            if (Object.keys(errors).length > 0) resetValidation()
            else formik.handleSubmit()
        })
    }, [shouldValidate])

    const handleSignUpFeeZero = async () => {
        try {
            console.log(cart)
            const response = await createCustomer({email: cart.mainUser.email, name: cart.mainUser.name, surname: cart.mainUser.surname})
            
            if (response) {
                console.log(response)
                const signUpResponse = await signUp(cart, response.customer_id)
                if (signUpResponse) {
                    console.log('utente creato', signUpResponse)
                    setShowThankYou(true)
                    hideSteps()
                    hideNavigation()
                }
            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (feeZero && cart.company) {
            handleSignUpFeeZero()
        }
        if (localStorage.getItem('speaker')) {
            saveCart({
                ...cart,
                mainUser: {
                    ...cart.mainUser,
                    speaker: 1
                }
            })
            localStorage.removeItem('speaker')
        }
    }, [cart])

    return (
        !showThankYou ? (
            <div className= "membership__section">
                <div className={`${style["signup__form"]}`}>
                    <MembershipTitle  title={title} />
                    <PageDescription  textContent={content}/>
                    <form onSubmit={formik.handleSubmit} className={`${style["form__container"]}`}> 
                        {/* <InputSelect 
                          placeholderText="Company Type"
                          name={"type"}
                          onChange={(event) => formik.setFieldValue('type', event.target.value)}
                          values={[
                            {value: "individual", label: "Individual"},
                            {value: "company", label: "Company"},
                            {value: "law firm", label: "Law firm"},
                            {value: "lawyer", label: "Lawyer"},
                            {value: "association", label: "Association"}
                          ]} 
                          selectedValue={formik.values.type}
                          errors={formik.errors.type}
                        /> */}
                        <InputField 
                            name="name" 
                            hasErrors={formik.touched.name && formik.errors.name} 
                            handleChange={formik.handleChange} 
                            value={formik.values.name} 
                            placeholderText="Company Name*" 
                            fieldType="text"
                            errors={formik.errors.name} /> 
                        <InputField 
                            name="vat" 
                            hasErrors={formik.touched.vat && formik.errors.vat} 
                            handleChange={formik.handleChange} 
                            value={formik.values.vat} 
                            placeholderText="Company VAT" 
                            fieldType="text" 
                            errors={formik.errors.vat}/>
                        <InputSelect 
                            placeholderText="Company Country*"
                            name={"country"}
                            onChange={(event) => formik.setFieldValue('country', event.target.value)}
                            values={countries.map(country => {
                                return country;
                            })}
                            selectedValue={formik.values.type}
                            errors={formik.errors.country}
                            />
                        <InputField 
                            name="address" 
                            hasErrors={formik.touched.address && formik.errors.address} 
                            handleChange={formik.handleChange} 
                            value={formik.values.address} 
                            placeholderText="Company Address*" 
                            fieldType="text" 
                            errors={formik.errors.address}/>
                        <InputField 
                            name="city" 
                            hasErrors={formik.touched.city && formik.errors.city} 
                            handleChange={formik.handleChange} 
                            value={formik.values.city} 
                            placeholderText="Company City*" 
                            fieldType="text" 
                            errors={formik.errors.city}/> 
                        <InputField 
                            name="province" 
                            hasErrors={formik.touched.province && formik.errors.province} 
                            handleChange={formik.handleChange} 
                            value={formik.values.province} 
                            placeholderText="Company Province*" 
                            fieldType="text"
                            errors={formik.errors.province} />
                        <InputField 
                            name="postal_code" 
                            hasErrors={formik.touched.postal_code && formik.errors.postal_code} 
                            handleChange={formik.handleChange} 
                            value={formik.values.postal_code} 
                            placeholderText="Company Postal Code*" 
                            fieldType="text" 
                            errors={formik.errors.postal_code}/>
                    </form>
                </div>
            </div>
        ) : (
            <ThankYou
            title="Thank you!"
            content="Your account has been created successfully"
            />    
        )
    )
}

export default CompanyInformation

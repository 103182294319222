import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import SignupAndConference from './pages/SignupAndConference';
import Documents from './pages/Documents';
import reportWebVitals from './reportWebVitals';
import MyProfile from './pages/MyProfile';
import RenewMembership from './pages/RenewMembership';
import LoginAndConference from './pages/LoginAndConference';
import SignupAndMembership from './pages/SignupAndMembership';
import Signup from './components/SignUp';
import { AppWrapper } from './context/AppContext';
import CountryExpertProfile from './pages/CountryExpertProfile';
import NavbarUserLogin from './components/NavbarUserLogin';
import CountryExpertPage from './pages/CountryExpertPage';
import SearchResults from './pages/SearchResults';
import HomepageSearchResults from './pages/HomepageSearchResults';
import ForgottenPassword from './pages/ForgottenPassword';
import ConfirmEmail from './pages/ConfirmEmail';
import LoginStep from './components/LoginStep';
import MembershipAreaPage from './pages/MembershipAreaPage';
import Idarb from './pages/Idarb';
import 'react-toastify/dist/ReactToastify.css';
import IdarbProfile from './pages/IdarbProfile';
import IdarbPage from './pages/IdarbPage';
import DocumentArea from './pages/DocumentArea';


if (document.getElementById('signUpAndConference')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><SignupAndConference /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('signUpAndConference')
  );
} 

if (document.getElementById('signUpAndMembership')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><SignupAndMembership/></AppWrapper>
    </React.StrictMode>,
    document.getElementById('signUpAndMembership')
  );
}

if (document.getElementById('signUp')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Signup/></AppWrapper>
    </React.StrictMode>,
    document.getElementById('signUp')
  );
}

if (document.getElementById('renewMembership')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><RenewMembership /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('renewMembership')
  );
}

if (document.getElementById('loginAndConference')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><LoginAndConference /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('loginAndConference')
  );
}

if (document.getElementById('documentsLegislation')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'Legislation'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsLegislation')
  );
}

if (document.getElementById('documentsInternationalConventions')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'International Conventions'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsInternationalConventions')
  );
}

if (document.getElementById('documentsContracts')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'Contracts'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsContracts')
  );
}

if (document.getElementById('documentArea')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><DocumentArea /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentArea')
  );
}

if (document.getElementById('documentsEUSection')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'EU Section'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsEUSection')
  );
} 

if (document.getElementById('documentsAntitrust')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'Antitrust'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsAntitrust')
  );
} 

if (document.getElementById('documentsApplicableLawJurisdiction')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'Applicable Law & Jurisdiction'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsApplicableLawJurisdiction')
  );
} 

if (document.getElementById('documentsArbitration')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'Arbitration'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsArbitration')
  );
} 

if (document.getElementById('documentsArbitralCases')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'Arbitral Cases'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsArbitralCases')
  );
} 

if (document.getElementById('documentsEUCaseLaw')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'EU Case Law'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsEUCaseLaw')
  );
} 

if (document.getElementById('documentsReports')){
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents category={'Reports'} /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documentsReports')
  );
}

if ( document.getElementById('user-profile') ) {
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><MyProfile /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('user-profile')
  );
  }
  
  if ( document.getElementById('documents') ) {
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper><Documents /></AppWrapper>
    </React.StrictMode>,
    document.getElementById('documents')
  );
  }
  

  if ( document.getElementById('country-experts') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><CountryExpertPage /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('country-experts')
    );
  }

  if ( document.getElementById('country-expert-profile') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><CountryExpertProfile /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('country-expert-profile')
    );
  }

  if ( document.getElementById('idarb-list') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><IdarbPage /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('idarb-list')
    );
  }

  if ( document.getElementById('idarb-profile') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><IdarbProfile /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('idarb-profile')
    );
  }


  if ( document.getElementById('user_login') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><NavbarUserLogin /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('user_login')
    );
  }
 
  if ( document.getElementById('searchResults') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><SearchResults /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('searchResults')
    );
  }

  if ( document.getElementById('forgottenPassword') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><ForgottenPassword /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('forgottenPassword')
    );
  }
  
  if ( document.getElementById('confirmEmail') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><ConfirmEmail /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('confirmEmail')
    );
  }

  if ( document.getElementById('loginStep') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><LoginStep /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('loginStep')
    );
  }

  if ( document.getElementById('memberArea') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><MembershipAreaPage /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('memberArea')
    );
  }

  if ( document.getElementById('idarbFlow') ) {
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><Idarb /></AppWrapper>
      </React.StrictMode>,
      document.getElementById('idarbFlow')
    );
  }

  if(document.getElementById('homepage-search-results')){
    ReactDOM.render(
      <React.StrictMode>
        <AppWrapper><HomepageSearchResults/></AppWrapper>
      </React.StrictMode>,
      document.getElementById('homepage-search-results')
    );
  }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();